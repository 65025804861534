<template>
  <div>
    <div class="account-info-container ">
      <div class="">
        <img src="../../public/images/logo.png" alt="">
      </div>
      <div class="account-info">
        <v-icon @click="is_zoom = !is_zoom" :color="is_zoom ? 'error' : '#1d4db1'" 
         class="zoom-btn px-2 fullscreen-btn"
        style="line-height: 1px; height:35px">
        mdi-fullscreen
      </v-icon>
        <span class="notify-btn " stacked v-if="user && (user.role_id == '61829dd1e64b1b17688326df' ||user.role_id == '61829dc7e64b1b17688326d5' ||user.role_id == '6424071f50b77c6cf890ab0d' )"
        @click="readNotification"
        >
          <v-badge bordered color="error" v-if="user.is_read == false">
            <v-icon class="notify-btn-icon">mdi-bell-outline</v-icon>
          </v-badge>
          <v-badge v-else color="">
            <v-icon class="notify-btn-icon">mdi-bell-outline</v-icon>
          </v-badge>
        </span>
        
        <v-icon style="line-height: 1px; ">mdi-account-circle</v-icon>: <span> {{ user ? user.user_name : 'loading..' }}
        </span>
        ( 
          <span v-if="eyeIsOn">
            <span class="xr-hide-mobi" style="font-weight: bold; color: rgb(253, 199, 3); text-shadow: -1px 0 2px black"
              v-if="user && user.role_id == '612a3fcc1a1fcead8871e822'">
              {{ user ? currencyFormatUsd(user.companyBalances[0].balance) + "$, "
              + currencyFormatKm(user.companyBalances[1].balance) + "៛, "
              + currencyFormatKm(user.companyBalances[2].balance) + "₫, "
              + currencyFormatKm(user.companyBalances[3].balance) + "฿ " : "" }}
            </span>
            <span class="xr-hide-mobi" style="font-weight: bold; color: rgb(253, 199, 3); text-shadow: -1px 0 2px black"
              v-else-if="user && user.role_id == '61829d84e64b1b17688326a3'">
              {{ user ? currencyFormatUsd(user.seniorBalance[0].balance) + "$, "
              + currencyFormatKm(user.seniorBalance[1].balance) + "៛, "
              + currencyFormatKm(user.seniorBalance[2].balance) + "₫, "
              + currencyFormatKm(user.seniorBalance[3].balance) + "฿ " : "" }}
            </span>
            <span class="xr-hide-mobi" style="font-weight: bold; color: rgb(253, 199, 3); text-shadow: -1px 0 2px black"
              v-else-if="user && user.role_id == '61829dbbe64b1b17688326cb'">
              {{ user ? currencyFormatUsd(user.masterBalance[0].balance) + "$, "
              + currencyFormatKm(user.masterBalance[1].balance) + "៛, "
              + currencyFormatKm(user.masterBalance[2].balance) + "₫, "
              + currencyFormatKm(user.masterBalance[3].balance) + "฿ " : "" }}
            </span>
            <span class="xr-hide-mobi" style="font-weight: bold; color: rgb(253, 199, 3); text-shadow: -1px 0 4px black"
              v-else>
              {{ user ? currencyFormatUsd(user.balance) : "" }}
              <span>{{ getUserCurrency() }}</span>
            </span>
          </span>
          <span v-else style="background-color: black; color: black;">######</span>
          <v-icon @click="changeEyeStatue(false)" v-if="eyeIsOn" style="line-height: 1px;">mdi-eye-outline</v-icon>
          <v-icon @click="changeEyeStatue(true)" v-else style="line-height: 1px; ">mdi-eye-off-outline</v-icon>
        )
        <v-icon @click="onLogout"  color="white" class=" bg-danger pa-3 leave-btn" style="height:35px; line-height: 1px;">
          mdi-close-box-outline
      </v-icon>
      </div>

    </div>
    <!-- <nav class="nav-style pa-0 my-0">
      <div v-for="(i, mIndex) in menu" :key="mIndex" :style="_calMenuWidth()" class="main-menu-div w-100 h-100"
        :id="`tt-${i.title}`">
        <a v-if="i.items.length == 0" @click="_goToRoute(i.url)">{{ i.title }}</a>
        <v-menu v-else :offset-y="true" open-on-hover close-on-content-click>
          <template v-slot:activator="{ on }">
            <div class="main-menu-title" :class="_calMenuMinWidth()" text v-on="on" style="min-width: none!important;" :style="getMMenuSelected == i._id ? 'background-color: #012d98; color: #f5c243 !important' : ''">
              {{ i.title }}
            </div>
          </template>
          <v-list class="submenu-list">
            <v-list-item v-for="(item, index) in i.items" :key="index" @click="_goToRoute(item, mIndex, i)" class="sub-menu-container">
              <v-list-item-title>
                <img v-if="_imgPath(item.icon)" :src="_getImgPath(item.icon)" class="child-submenu-icon" />
                <v-icon v-else style="margin-right: 5px !important;">
                  {{ item.icon }}
                </v-icon> {{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </nav>-->
    <!-- <div class="container" style="display: flex; width:100%; background-color: #012d98; color: #f5c243; height: 30px; padding-left: 18px; padding-right: 18px; padding-top: 2px;">
      <div class="item" @click="_goToSubRoute(submenu, sIndex)" v-for="(submenu, sIndex) in getSubmenu" style="padding-left: 2px; padding-right: 2px;" :key="submenu._id" :style="subMenuSelected == submenu._id ? 'color: #f5c243 !important' : 'color: #ffffff !important'">
        <a>
          <img v-if="_imgPath(submenu.icon)" :src="_getImgPath(submenu.icon)" class="child-submenu-icon" />
          <v-icon v-else style="margin-right: 5px !important; height: 28px; width: 28px;" :style="subMenuSelected == submenu._id ? 'color: #f5c243 !important' : 'color: #ffffff !important'">
            {{ submenu.icon }}
          </v-icon>
          <b>{{ submenu.title }}</b>
        </a>
      </div>
    </div> -->
    <!-- <div id="menu-container" class="gp-shadow-md d-flex gp-top-nav justify-content-between align-items-center">
      <div class="menu-space">
        <v-row class="ma-0 pl-17 w-100">
          <div class="w-100 d-flex align-center">
            <div class="row ma-0">
              <div class="text-center d-flex align-items-center menu-slot-item" v-for="(i, mIndex) in menu" :key="mIndex" :style="_calMenuWidth()">
                <div class="nav-lottery-main-menu nav-icon-wr row ma-0 pt-0">
                  <p>{{ i.title }} </p>
                </div>
              </div>
            </div>
          </div>
        </v-row>
      </div>
    </div> -->
    <div class="main-menu-tab-game-position ma-0 pa-0" style="overflow:hidden; background-color: #1d4db1 ;">
      <a v-for="(i, mIndex) in menu" :key="mIndex" @click="_goToRoute(i.items[0], mIndex, i)" :style="_calMenuWidth()" :class="menuSelected == i._id ?  'main-menu-span-active' : ''" class="btn main-menu-btn-custom pa-0 ma-0"
        style="text-align: center">
        <p class="mainmenu-name">{{ i.title }}</p>
      </a>
    </div>
    <div id="menu-container" class="gp-shadow-md d-flex gp-top-nav justify-content-between align-items-center">
      <div class="menu-space">
        <v-row class="ma-0 pl-17 w-100">
          <div class="w-100 d-flex align-center">
            <div class="row ma-0">
              <div class="text-center d-flex align-items-center menu-slot-item " @click="_goToSubRoute(submenu, sIndex)" v-for="(submenu, sIndex) in getSubmenu" :key="submenu._id" >
                <div class="nav-lottery-main-menu nav-icon-wr row ma-0 pt-0" :class="getSMenuSelected == submenu._id ? 'main-menu-span-active ' : ''">
                  <img v-if="_imgPath(submenu.icon)" :src="_getImgPath(submenu.icon)" class="child-sub-menu-icon" />
          <v-icon v-else style="margin-right: 0px !important" :style="getSMenuSelected == submenu._id ? 'color: #f5c243 !important' : 'color: #ffffff !important'">
            {{ submenu.icon }}
          </v-icon>
                  <p>{{ submenu.title }}</p> 
                </div>
              </div>
            </div>
          </div>
        </v-row>
      </div>
    </div>
    <!-- <template>
        <v-tabs
      bg-color="teal-darken-3"
      slider-color="teal-lighten-3"
          next-icon="mdi-arrow-right-bold-box-outline"
          prev-icon="mdi-arrow-left-bold-box-outline"
          show-arrows
        >
          <v-tab
            v-for="submenu in getSubmenu"
            :key="submenu._id"
            :text="`submenu.title ${i}`"
          ></v-tab>
        </v-tabs>
    </template> -->
  </div>
</template>

<script>
import "../../public/assets/css/header/screen_max_480.css";
import "../../public/assets/css/header/screen_min_481.css";
import "../../public/assets/css/header/screen_min_768.css";
import "../../public/assets/css/header/screen_min_993.css";
import "../../public/assets/css/header/screen_min_1025.css";
import "../../public/assets/css/header/screen_min_1281.css";
import "../../public/assets/css/header/screen_min_1441.css";
import "../../public/assets/css/header/screen_min_1661.css";
import "../../public/assets/css/header/screen_min_1824.css";
import "../../public/assets/css/header/header_share_style.css";
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
  data: () => ({
    is_menu: false,
    is_zoom: false,
    menuSelected: '611fde700734048a0481201e',
    selectedMenu: "User",
    menuIcon: "mdi-account",
    subMenuSelected: '611fee7e0734048a04812022',
  }),
  computed: {
    ...mapGetters("$_modules", {
      drawer: "getDrawer",
      user: "getUser",
      menu: "getMenu",
      userInfo: "getUserInfo",
      eyeIsOn: "getEyeIsOn",
      getSubmenu: "getSubmenu",
      getMMenuSelected: "getMMenuSelected",
      getSMenuSelected: "getSMenuSelected"
    }),

  },
  watch: {
    user() { },
  },
  async mounted() {
    this.$socketG.on("server-notification", data => {
      if (data) {
        this.user.is_read = false;
      }
    });
    this.isSuspend();
  },
  async created() {
    this.menuSelected = this.$cookies.get("sbcad_menu_selected") ? this.$cookies.get("sbcad_menu_selected") : this.getMMenuSelected;
    this.subMenuSelected = this.$cookies.get("sbcad_submenu_selected") ? this.$cookies.get("sbcad_submenu_selected") : this.getSMenuSelected;
    console.log('this.subMenuSelectedh :::: ', this.subMenuSelected);
    await this.getUserInfo();
    this.isSuspend();
  },
  methods: {
    changeEyeStatue(eyeIsOn){
      this.UPDATE_EYE_IS_ON(eyeIsOn)
    },
    _imgPath(data) {
      try {
        console.log(data);
        require('../../public/images/menu_icons/' + data);
        return true
      } catch (e) {

        false
      }
    },
    _getImgPath(data) {
      try {
        return require('../../public/images/menu_icons/' + data);
      } catch (e) {
        false
      }
    },

    _goToRoute(item, index, itemMenu) {
      // console.log('_goToSubRouteitemfrommain :::: ', JSON.stringify(itemMenu));
      this.menuSelected = itemMenu._id;
      this.selectedMenu = item.title;
      this.menuIcon = item.icon;
      this.subMenuSelected = item._id;
      this.UPDATE_SUBMENU_LIST(itemMenu.items);
      this.UPDATE_MMENU_SELECTED(this.menuSelected);
      this.UPDATE_SMENU_SELECTED(this.subMenuSelected);
      this.$cookies.set("sbcad_menu_selected", this.menuSelected);
      this.$cookies.set("sbcad_submenu_selected", this.subMenuSelected);
      this.$cookies.set("sbcad_sub_index", index);
      this.$router.push({ path: item.url });
    },
    _goToSubRoute(item, sIndex) {
      this.subMenuSelected = item._id;
      // console.log('this.subMenuSelectedg :::: ', this.subMenuSelected);
      this.$cookies.set("sbcad_submenu_selected", this.subMenuSelected);
      // console.log('_goToSubRouteitem :::: ', sIndex);
      // this.$cookies.set("sbcad_sub_index", sIndex);
      this.UPDATE_SMENU_SELECTED(this.subMenuSelected);
      this.$cookies.set("sbcad_submenu_index", sIndex);
      this.$router.push({ path: item.url });
    },
    _calMenuWidth() {
      return 'width:' + 100 / this.menu.length + '%;';
    },
    _calMenuMinWidth() {
      return 'min-width:' + 100 / this.menu.length + '%;';
    },
    _playSideBar() {
      this.UPDATE_DRAWER(!this.drawer);
    },
    currencyFormatUsd(num) {
      num = parseFloat(num);
      return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },
    currencyFormatKm(num) {
      num = parseFloat(num);
      return parseInt(num).toLocaleString("en");
    },
    getUserCurrency() {
      if (this.user) {
        if (this.user.currency_type === 1) {
          return "$";
        } else if (this.user.currency_type === 2) {
          return "៛";
        } else if (this.user.currency_type === 3) {
          return "฿";
        } else if (this.user.currency_type === 4) {
          return "₫";
        } else if (this.user.currency_type === 5) {
          return "฿";
        } else {
          return this.user.currency_type;
        }
      }
    },
    async getUserInfo() {
      await this.fetchUser();
    },
    isSuspend() {
      console.log("ddd", this.user);
      if (this.user) {
        if (this.user.is_suspend) {
          this.onLogout()
        }
      }
    },
    async onLogout() {
      let dealerId = this.$cookies.get("dealerId");
      if (dealerId) {
        let data = {
          barcode: dealerId.barcode,
        };
        await this.$request.post({
          url: "dealer/inActiveDealer",
          data: data,
        });
      }
      this.$cookies.remove("dealerId");
      this.$cookies.remove("token");
      this.$router.push("/login");
    },
    async readNotification() {
      let data = {};
      await this.$request
        .post({
          url: "/bankStatement/readNotification",
          data: data,
        })
        .then((res) => {
          if (res.data.code) {
            this.user.is_read = true;
            this.$router.push({ path: "/bankStatement"});
            // this.$router.go("/bankStatement");
          }
        });
    },
    balance_click() {
      // alert('xxxxx');
      if (document.getElementsByClassName('xr-box-balance')[0].classList.contains('xr-box-balance-hide')) {
        //alert('xxxxx')
        document.getElementsByClassName('xr-box-balance')[0].classList.remove('xr-box-balance-hide');
        document.getElementsByClassName('xr-box-balance')[0].classList.add('xr-box-balance-show');
      } else {
        document.getElementsByClassName('xr-box-balance')[0].classList.add('xr-box-balance-hide');
        document.getElementsByClassName('xr-box-balance')[0].classList.remove('xr-box-balance-show');
      }

    },
    ...mapMutations("$_modules", ["UPDATE_DRAWER", "UPDATE_EYE_IS_ON", "UPDATE_SUBMENU_LIST", 'UPDATE_SMENU_SELECTED', 'UPDATE_MMENU_SELECTED']),
    ...mapActions("$_modules", ["fetchUser"]),
  },
};
</script>
<style>
.container {
    width: 100%;
    height: fit-content;

    /**used to scroll text*/
    white-space: nowrap;    
    overflow-x: scroll;
    overflow-y: hidden;
    /**keep scrolling when
      *finger or cursor scrolling*/
    -webkit-overflow-scrolling: touch; 
}
.btn:not(.btn-social):not(.btn-social-icon):active, .btn:not(.btn-social):not(.btn-social-icon):focus, .btn:not(.btn-social):not(.btn-social-icon):hover {
  background-color: #012d98 !important;
}
.main-menu-span-active {
    background-color: #012d98 !important;
  }
  .main-menu-span-active > img{
    filter: drop-shadow(0px 1000px 0 #fec002);
    transform: translateY(-1000px);
  }
  .main-menu-span-active > .main-menu-tab-logo{
    filter: drop-shadow(0px 1000px 0 #fec002);
    transform: translateY(-1000px);
  }
  .main-menu-span-active > p{
    color:#fec002!important;
  }
.mainmenu-name{
        color:white;
        font-size: .6rem;
        font-weight: 700;
        padding:0px;
      }
.item {
    float: none;
    display: inline-block;
}
/*to hide the scrollbars*/
::-webkit-scrollbar {
    display: none;
}
.main-menu-tab-game-position {
        display: flex;
      }
#menu-container {
    height: auto;
    width: 100%;
    min-width: 100%;
    background-color: #0437b4;
    border-top: 2px solid #466eac;
    }
    #menu-container p {
        color:white;
        padding-left :5px;
        margin-bottom:0px;
        margin-top:0px;
        font-weight:700;
        font-size:.7rem;
        padding-top:12px;
    }
    .gp-shadow-md {
    -webkit-box-shadow: 1px 3px 9px -3px #0e0e0ee6;
    box-shadow: 1px 3px 9px -3px #0e0e0ee6
}

.menu-space {
    padding: 0 !important;
    margin-top:0px;
    max-width: 100% !important;
    }

.nav-icon-wr {
    padding: .5rem;
    text-align: center
}

/* .menu-slot-item{
        width:25%;
    } */
    .menu-slot-item:hover {
        /* background-color: #0b407c; */
        cursor: pointer;
    }
    .nav-lottery-main-menu{
        justify-content: center;
    }
    .menu-slot-item:hover >.nav-lottery-main-menu> .lottery-main-game-image {
        filter: drop-shadow(0px 1000px 0 #fec002);
        transform: translateY(-1000px);
    }
    .menu-slot-item:hover >.nav-lottery-main-menu> p {
        color:#fec002!important;
    }
    .menu-slot-item:hover >.nav-lottery-main-menu{
        background-color:#012d98!important;
    }
  .main-menu-span-active {
    background-color: #012d98;
  }
  .main-menu-span-active > img{
    filter: drop-shadow(0px 1000px 0 #fec002);
    transform: translateY(-1000px);
  }
  .main-menu-span-active > .main-menu-tab-logo{
    filter: drop-shadow(0px 1000px 0 #fec002);
    transform: translateY(-1000px);
  }
  .main-menu-span-active > p{
    color:#fec002!important;
  }
  .child-sub-menu-icon{
    margin-top: 12px;
  object-fit: contain;
  width: 22px !important;
  height: 22px !important;
  margin-right: 2px !important;
  filter: drop-shadow(-1px 0 3px black);
  }
  .main-menu-tab-game-position {
        height:48px!important;
        display: flex;
      }
  .mainmenu-name{
        color:white;
        font-size: 1rem;
        font-weight: 700;
        padding:8px;
      }
</style>